<template>
  <div class="export-page">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Create new export </v-card-title>
          <v-card-text>
            <v-text-field
              class="mb-3"
              name="filename"
              label="File name"
              v-model="exportName"
              dense
              hide-details
              outlined
            />
            <v-select
              class="mb-3"
              label="Export type"
              :items="selectOptions.exportTypeList"
              v-model="exportType"
              dense
              hide-details
              outlined
            >
            </v-select>
            <v-select
              class="mb-3"
              v-if="exportType === 'raw'"
              label="File type"
              :items="exportFileTypeList"
              v-model="exportFileType"
              dense
              hide-details
              outlined
            />
            <v-select
              class="mb-3"
              v-if="exportType === 'report'"
              label="File type"
              :items="selectOptions.reportFileTypeList"
              v-model="reportFileType"
              dense
              hide-details
              outlined
            />
            <v-select
              class="mb-3 w-100"
              v-model="filterData.sentiment"
              :items="selectOptions.sentimentItem"
              label="Sentiment"
              multiple
              chips
              outlined
              small-chips
              deletable-chips
              hide-details="auto"
              :error-messages="
                invalidSentiment
                  ? ['At least one sentiment must be selected.']
                  : []
              "
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleAll('sentiment')">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        filterData.sentiment && filterData.sentiment.length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                    >
                      {{ isAllSelected('sentiment') }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Select All </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            <v-select
              v-model="filterData.source"
              :items="sourceList"
              class="w-100 mb-3"
              label="Source"
              multiple
              chips
              outlined
              small-chips
              hide-details="auto"
              :error-messages="
                invalidSource ? ['At least one source must be selected.'] : []
              "
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0" class="grey--text caption">
                  {{ filterData.source.length }} selected
                </span>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleAll('source')">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        filterData.source && filterData.source.length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                    >
                      {{ isAllSelected('source') }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Select All </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            <CategoryTreeSelect
              id="filter-category"
              class="mb-3"
              :criteria="filterData"
              @update="onCategoryChange"
            />
            <v-select
              v-model="selectedDateMode"
              @change="onChangeDateMode"
              :items="dateModeList"
              class="w-100"
              label="From period"
              dense
              outlined
              hide-details
            >
            </v-select>
            <template v-if="selectedDateMode === 'custom'">
              <v-menu
                v-model="dateMenuOpen"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mb-3"
                    v-model="formattedCustomDate"
                    label="Pick date range"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="customDateInput" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="'#4c7994'" @click="dateMenuOpen = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="setCustomDate">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </template>
            <template v-if="selectedDateMode === 'epoch' && filterData.time">
              <v-text-field
                class="mb-3"
                v-model="filterData.time.sinceDate"
                label="Since"
                hide-details="auto"
                dense
                outlined
              />
              <v-text-field
                class="mb-3"
                v-model="filterData.time.untilDate"
                label="Until"
                hide-details="auto"
                dense
                outlined
              />
            </template>
            <v-expansion-panels flat accordion>
              <v-expansion-panel>
                <v-expansion-panel-header class="px-0">
                  <u>Advance filter</u>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="expansion-no-wrap">
                  <v-text-field
                    class="mb-3"
                    v-model="filterData.keywordPhrase"
                    label="Search Phrase"
                    hide-details="auto"
                    dense
                    outlined
                  />
                  <v-text-field
                    class="mb-3"
                    v-model="filterData.excludeKeywordPhrase"
                    label="Exclude Phrase"
                    hide-details="auto"
                    dense
                    outlined
                  />
                  <v-text-field
                    class="mb-3"
                    v-model="filterData.username"
                    label="Username"
                    hide-details="auto"
                    dense
                    outlined
                  />
                  <template v-if="filterData && filterData.sort">
                    <v-select
                      class="mb-3 w-100"
                      v-model="filterData.sort.field"
                      :items="selectOptions.sortFieldList"
                      label="Sort Using"
                      dense
                      outlined
                      hide-details
                    ></v-select>
                    <v-select
                      v-model="filterData.sort.direction"
                      :items="selectOptions.sortDirectionList"
                      class="mb-3 w-100"
                      label="Sort Direction"
                      dense
                      outlined
                      hide-details
                    ></v-select>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions class="px-3 pb-3 export-action">
            <v-spacer></v-spacer>
            <v-btn @click="exportData" :disabled="isExporting">Export</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title> Export history </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="exportHeaders"
              :items="exportHistory"
              hide-default-footer
              class="elevation-1 mb-2"
            >
              <template v-slot:[`item.file_name`]="{ item }">
                {{ item.file_name || item._id }}
              </template>
              <template v-slot:[`item.exported_status`]="{ item }">
                {{ item.exported_status | capitalize }}
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | timeFromNow }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <template
                  v-if="
                    item.exported_status === 'generated' ||
                    item.exported_status === 'downloaded'
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        @click="downloadFile(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>fa fa-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download file</span>
                  </v-tooltip>
                </template>
                <template v-else-if="item.exported_status === 'failed'">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        @click="retryExport(item._id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>fa fa-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Retry export</span>
                  </v-tooltip>
                </template>
                <template v-else-if="item.exported_status === 'generating'">
                  <span v-if="item.exported_progress === 0"> In progress </span>
                  <span v-else>
                    {{ item.exported_progress | numeral(0.0) }}%
                  </span>
                </template>
                <template v-else-if="item.exported_status === 'new'">
                  <span> Waiting in queue </span>
                </template>
                <template
                  v-if="
                    item.exported_status === 'downloaded' ||
                    item.exported_status === 'generated'
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        @click="removeExport(item._id, item.file_name, false)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>fa fa-trash</v-icon>
                      </v-btn>
                    </template>
                    <span>Remove export info</span>
                  </v-tooltip>
                </template>
                <template
                  v-if="
                    (item.exported_status === 'new' ||
                      item.exported_status === 'generating') &&
                    item.file_type !== 'pdf'
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        @click="removeExport(item._id, item.file_name, true)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>fa fa-times</v-icon>
                      </v-btn>
                    </template>
                    <span>Cancel export</span>
                  </v-tooltip>
                </template>
              </template>
            </v-data-table>
            <v-pagination
              v-model="currentPage"
              :length="totalPage"
              :total-visible="5"
              @change="getExportStatus"
            ></v-pagination>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackBarOpen"
      app
      absolute
      timeout="2000"
      top
      right
      :color="snackBarType"
    >
      {{ snackBarText }}
    </v-snackbar>
    <ConfirmDialog
      :open="confirmOpen"
      :content="confirmContent"
      okButton="Remove"
      @close="onConfirmRemove"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

import helper from '@/services/helper';
import api from '@/services/api';
import selectOptions from '@/services/select-options';

import CategoryTreeSelect from '@/components/Filter/CategoryTreeSelect.vue';
import ConfirmDialog from '@/components/Dialog/ConfirmDialog.vue';

export default {
  name: 'ExportPage',
  components: {
    CategoryTreeSelect,
    ConfirmDialog,
  },
  data() {
    return {
      selectOptions: selectOptions,
      exportName: '',
      exportType: 'report',
      exportFileType: 'csv',
      reportFileType: 'pdf',
      filterData: {},
      selectedDateMode: 'default',
      customDateInput: null,
      dateMenuOpen: false,
      dateModeList: [],
      exportHeaders: [
        { text: 'Name', value: 'file_name' },
        { text: 'Type', value: 'file_type' },
        { text: 'Status', value: 'exported_status' },
        { text: 'Time', value: 'created_at' },
        { text: 'Actions', value: 'action' },
      ],
      exportHistory: [],
      currentPage: 1,
      totalPage: 1,
      isExporting: false,
      canExport: true,
      activeInterval: null,
      // confirm
      confirmOpen: false,
      confirmContent: '',
      removingId: '',
      // snack
      snackBarOpen: false,
      snackBarText: '',
      snackBarType: 'success',
    };
  },
  async created() {
    this.filterData = await this.$store
      .dispatch('filter/initExportFilter')
      .catch(() => {});
    this.dateModeList = [
      { value: 'default', text: `Default (${this.queryLength} days)` },
      ...this.selectOptions.dateModeList,
    ];
    if (this.userRole === 'domadmin') {
      // enable epoch input
      this.dateModeList.push({ value: 'epoch', text: 'Epoch' });
    }
    this.getExportStatus();
    this.activeInterval = setInterval(() => {
      this.getExportStatus();
    }, 60000);
  },
  beforeDestroy() {
    if (this.activeInterval) {
      clearInterval(this.activeInterval);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.activeInterval) {
      clearInterval(this.activeInterval);
    }
    next();
  },
  methods: {
    toggleAll(type) {
      const current = this.filterData[type] || [];
      let target;
      let val = [];
      if (type === 'sentiment') {
        target = this.selectOptions.sentimentItem;
      } else if (type === 'source') {
        target = this.sourceList;
      }
      const l = current.length;
      if (l >= 0 && l !== target.length) {
        val = target.map((x) => x.value);
      }
      Vue.set(this.filterData, type, val);
    },
    isAllSelected(type) {
      const current = this.filterData[type];
      let target;
      if (type === 'sentiment') {
        target = this.selectOptions.sentimentItem;
      } else if (type === 'source') {
        target = this.sourceList;
      }
      if (current) {
        const l = current.length;
        if (l === target.length) {
          return 'mdi-close-box';
        }
        if (l > 0) {
          return 'mdi-minus-box';
        }
      }
      return 'mdi-checkbox-blank-outline';
    },
    onCategoryChange({ category, subCategory }) {
      // console.log(category, subCategory);
      Vue.set(this.filterData, 'category', category);
      Vue.set(this.filterData, 'subCategory', subCategory);
    },
    onChangeDateMode(mode) {
      Vue.set(this.filterData, 'time', helper.getDateByMode(mode));
    },
    setCustomDate() {
      if (this.customDateInput.length === 2) {
        const a = dayjs(this.customDateInput[0]);
        const b = dayjs(this.customDateInput[1]);
        const diff = a.diff(b);
        const time = {
          sinceDate: '',
          untilDate: '',
        };
        if (diff > 0) {
          // a is more recent
          time.sinceDate = b.startOf('day').valueOf();
          time.untilDate = a.endOf('day').valueOf();
        } else {
          // a is less or equal
          time.sinceDate = a.startOf('day').valueOf();
          time.untilDate = b.endOf('day').valueOf();
        }
        Vue.set(this.filterData, 'time', time);
        this.dateMenuOpen = false;
      }
    },
    async exportData() {
      this.isExporting = true;
      let type = this.reportFileType;
      if (this.exportType === 'raw') type = this.exportFileType;
      await api
        .newExport(this.filterData, type, this.exportName)
        .catch(() => {});
      this.getExportStatus();
      this.isExporting = false;
    },
    async getExportStatus() {
      const result = await api
        .getExportStatus(this.userName, this.currentPage)
        .catch(() => {});
      if (result && result.message) {
        const { numPages, canExport, records } = result.message;
        this.totalPage = numPages;
        this.canExport = canExport;
        this.exportHistory = records;
      }
    },
    async downloadFile(item) {
      const { _id, exported_status, account } = item;
      if (exported_status === 'generated') {
        // set downloaded for first download
        await api.setDownloadedStatus(_id).catch(() => {});
        this.getExportStatus();
      }
      window.open(
        `/services/export-download?id=${_id}&account=${account}`,
        '_blank'
      );
    },
    removeExport(id, name, isCancel) {
      let title;
      if (isCancel) {
        title = 'Would you like to cancel exporting of "' + name + '"?';
      } else {
        title = 'Would you like to delete export "' + name + '"?';
      }
      this.removingId = id;
      this.confirmContent = title;
      this.confirmOpen = true;
    },
    onConfirmRemove(state) {
      if (state) {
        api.removeExport(this.removingId).then(() => {
          this.getExportStatus();
          this.showSnackBar('success', 'Remove success.');
        });
      }
      this.confirmOpen = false;
    },
    retryExport(id) {
      api
        .setRetryStatus(id)
        .then(() => {
          this.getExportStatus();
        })
        .catch(() => {});
    },
    showSnackBar(type, text) {
      this.snackBarType = type;
      this.snackBarText = text;
      this.snackBarOpen = true;
    },
  },
  computed: {
    ...mapGetters({
      sourceList: 'account/sortedSourceList',
      userRole: 'account/userRole',
      userName: 'account/userName',
      queryLength: 'account/queryLength',
    }),
    invalidSentiment() {
      if (this.filterData) {
        const { sentiment } = this.filterData;
        if (!sentiment || sentiment.length === 0) return true;
      }
      return false;
    },
    invalidSource() {
      if (this.filterData) {
        const { source } = this.filterData;
        if (!source || source.length === 0) return true;
      }
      return false;
    },
    invalidCategory() {
      if (this.filterData) {
        const { category, subCategory } = this.filterData;
        console.log(category, subCategory);

        let isInvalid = false;
        if (!category || category.length === 0) isInvalid = true;
        if (!subCategory) isInvalid = true;
        else {
          for (let c of subCategory) {
            if (c.category && c.category.length > 0) {
              isInvalid = false;
              break;
            }
          }
        }
        return isInvalid;
      }
      return false;
    },
    invalidFilter() {
      // Invalid filter on no source, no sentiment, not category selected
      return (
        this.invalidSentiment || this.invalidSource || this.invalidCategory
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.export-page {
  padding: 1rem;
  .export-action {
    background-color: #ddd;
  }
}
</style>
